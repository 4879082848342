import React, { lazy } from "react";
import ReactDOM from "react-dom/client";
import Temp from "./Temp";
import "./Stylesheets/App.css";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
const App = lazy(() => import("./App"));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    {window.location.pathname.includes("/awareness") &&
    !localStorage.getItem("msisdn") ? (
      <Temp />
    ) : (
      <App />
    )}
  </BrowserRouter>
);
serviceWorkerRegistration.register();
