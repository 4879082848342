import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TagManager from "react-gtm-module";
import { fetchGetAuthHttp } from "../Utils/FetchApi";
import ApiNames from "../Constants/ApiNames";
import { encode as base64_encode } from "base-64";
import tagManagerEvents from "../Utils/GoogleTagManager";
import Awareness from "../Components/Awareness";

let adNetwork = "";
const AwarenessScreen = (props) => {
  const search = useLocation().search;
  const utm_source = new URLSearchParams(search).get("utm_source");
  useEffect(() => {
    if (props.token) {
      checkUtm();
      getAuthFlow();
    }
  }, [props.token]);
  useEffect(() => {
    gtmInitialization();
  }, []);

  const gtmInitialization = () => {
    const tagManagerArgs = {
      gtmId: "GTM-WSBVX5P",
    };
    TagManager.initialize(tagManagerArgs);
  };

  const checkUtm = async () => {
    try {
      let response = await fetchGetAuthHttp(ApiNames.utm, props.token);

      if (response.status === 200) {
        {
          response.response.map((e) => {
            if (utm_source === e.sf_utm) {
              // setSubUtm("econceptions");
              // setAdNetwork(e.ad_network);
              adNetwork = e.ad_network;
            }
          });
        }
      }
    } catch (err) {
      console.log("UTM failed: " + err);
    }
  };

  const getAuthFlow = async () => {
    try {
      tagManagerEvents("Request_Initiated", utm_source);
      let response = await fetchGetAuthHttp(
        ApiNames.awarenessFlow + "?utm_source=" + utm_source,
        props.token
      );
      if (response.status === 200) {
        if (response.response.result === 2 || response.response.result === 0) {
          if (
            adNetwork.toLowerCase() === "tiktok" ||
            adNetwork.toLowerCase() === "tik tok"
          ) {
            tagManagerEvents("subscribe", "econTikTok");
          } else if (adNetwork.toLowerCase() === "google") {
            tagManagerEvents("subscribe", "econceptions");
          } else {
            tagManagerEvents("subscribe", utm_source);
          }
          tagManagerEvents("Request_Success", utm_source);
          let url = window.location.origin;
          let encodedMsisdn = base64_encode(response.response.msisdn);

          url = `${url}/home?ndsism=${encodedMsisdn}`;
          if (url.includes("http:")) {
            url = url.replace("http", "https");
          }
          window.location.replace(url);
        } else {
          tagManagerEvents("Request_Failed", utm_source);
          navigateToHome("");
        }
      } else {
        tagManagerEvents("Request_Failed", utm_source);
        navigateToHome("");
        console.log("Something went wrong.");
      }
    } catch (err) {
      tagManagerEvents("Request_Failed", utm_source);
      navigateToHome("");
      console.log("Error :" + err);
    }
  };

  // const headerEnrichment = (response) => {

  //   try {
  //     if (response.isSpecialFlow === true) {
  //       if (response.msisdn !== null && response.otp !== "") {
  //         fetchSubscribeFlow(
  //           response.package,
  //           response.isSpecialFlow,
  //           response.fastFlow,
  //           response.msisdn
  //         );
  //       } else {
  //           navigateToHome("")
  //       }
  //     } else {
  //       navigateToHome("")
  //     }
  //   } catch (error) {
  //     console.log("headerEnrcihment Error", error);
  //     navigateToHome("")
  //   }
  // };

  // const fetchSubscribeFlow = async (
  //   userPackage,
  //   isSpecialFlow,
  //   fastFlow,
  //   msisdn
  // ) => {
  //   try {
  //     let response = await fetchGetAuthHttp(
  //       isSpecialFlow && fastFlow
  //         ? ApiNames.isFastFlow +
  //             "?msisdn=" +
  //             msisdn +
  //             "&utm_source=" +
  //             utm_source +
  //             "&subPackage=" +
  //             userPackage
  //         : ApiNames.fetchSubscribeApi +
  //             "?msisdn=" +
  //             msisdn +
  //             "&utm_source=" +
  //             utm_source +
  //             "&subPackage=" +
  //             userPackage,
  //       props.token
  //     );
  //     if (response.status === 200) {
  //       if (response.response.result === 2 || response.response.result === 0) {
  //         if (
  //           adNetwork.toLowerCase() === "tiktok" ||
  //           adNetwork.toLowerCase() === "tik tok"
  //         ) {
  //           tagManagerEvents("subscribe", "econTikTok");
  //         } else if (adNetwork.toLowerCase() === "google") {
  //           tagManagerEvents("subscribe", "econceptions");
  //         } else {
  //           tagManagerEvents("subscribe", utm_source);
  //         }

  //         let url = window.location.origin;
  //         let encodedMsisdn = base64_encode(msisdn);

  //         url = `${url}/home?ndsism=${encodedMsisdn}`;
  //         if (url.includes("http:")) {
  //           url = url.replace("http", "https");
  //         }
  //         window.location.replace(url);
  //       } else {
  //           navigateToHome("")
  //       }
  //     } else {
  //       navigateToHome("")
  //       console.log("failed to subscribe user");
  //     }
  //   } catch (error) {
  //       navigateToHome("")
  //     console.log("fetchSubscribeFlow error", error);
  //   }
  // };

  const navigateToHome = () => {
    let url = window.location.origin;
    url = `${url}/home`;
    if (url.includes("http:")) {
      url = url.replace("http", "https");
    }
    window.location.replace(url);
  };
  return <Awareness />;
};

export default AwarenessScreen;
