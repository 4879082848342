import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import ApiNames from "./Constants/ApiNames";
import { generateTokenPost } from "./Utils/FetchApi";
import AwarenessScreen from "./Screens/AwarenessScreen";
export default function Temp() {
  const [token, setToken] = useState(null);

  useEffect(() => {
    generateToken();
  }, []);

  const generateToken = async () => {
    try {
      let response = await generateTokenPost(ApiNames.generateToken);
      if (response.status === 200) {
        setToken(response.access_token);
      } else {
        console.log("Authentication failed. Please try again.");
      }
    } catch (error) {
      console.warn("token authentication failed", error);
    }
  };
  return (
    <div>
      <Routes>
        <Route
          path="/awareness"
          element={<AwarenessScreen token={token} isLanding={true} />}
        />
      </Routes>
    </div>
  );
}
